import Carousel from 'react-slick';

// import Image from 'src/components/image';
import dynamic from 'next/dynamic';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import { useRouter } from 'next/router';
const Image = dynamic(import('src/components/image'));

const SectionCover = ({ handleToPage, data }) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };
  const router = useRouter();

  // console.log(data);
  return (
    <>
      <div className='show-desktop'>
        {
          <Carousel aria-hidden='true' {...settings}>
            {data
              .filter(data => data.imagePath !== null)
              .map((item, index) => (
                <div key={index} className='banner-special'>
                  <a
                    href={!item.url ? '#' : item.url}
                    //! DATALAYER
                    onClick={() =>
                      item.url &&
                      sendDataLayerGlobal({
                        type: DATALAYER_TYPE.TRACK_INDEX,
                        router: router?.pathname,
                        section: 'imagecover',
                        data: {
                          title: item.title,
                          heading: 'imagecover',
                          carousel: index + 1
                        }
                      })
                    }
                    target={!item.url ? '_self' : '_blank'}
                    rel={!item.url ? '' : 'noopener noreferrer nofollow'}
                  >
                    <Image title={item.title} image={`/uploads/images/event/${item.imagePath}`} type='COVER_IMAGE' />
                  </a>
                </div>
              ))}
          </Carousel>
        }
      </div>
      <div className='show-mobile'>
        {
          <Carousel aria-hidden='true' {...settings}>
            {data
              .filter(data => data.imagePathMobile !== null)
              .map((item, index) => (
                <div key={index} className='banner-special'>
                  <a
                    href={!item.url ? '#' : item.url}
                    target={!item.url ? '_self' : '_blank'}
                    rel={!item.url ? '' : 'noopener noreferrer nofollow'}
                    //! DATALAYER
                    onClick={() =>
                      item.url &&
                      sendDataLayerGlobal({
                        type: DATALAYER_TYPE.TRACK_INDEX,
                        router: router?.pathname,
                        section: 'imagecover',
                        data: {
                          title: item.title,
                          heading: 'imagecover',
                          carousel: index + 1
                        }
                      })
                    }
                  >
                    <Image title={item.title} image={`/uploads/images/event/${item.imagePathMobile}`} type='FEATURE_IMAGE' />
                  </a>
                </div>
              ))}
          </Carousel>
        }
      </div>
    </>
  );
};

export default SectionCover;
